import React from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { image as imagePropTypes } from 'lib/CustomPropTypes';
import { Picture } from 'components/Picture';
import Caption from 'components/Caption';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import './styles.themed.scss';

/**
* ImageLiftout component renders an image with optional caption and link.
*
* @param {object} props - The properties object.
* @param {object} props.content - The content object containing image details and link information.
* @param {boolean} props.lazyloaded - Flag to determine if the image should be lazyloaded.
* @param {string} props.elementId - The optional element ID for the figure element.
* @returns {React.Element} The rendered ImageLiftout component.
*/
function ImageLiftout({
  content,
  lazyloaded = true,
  elementId = '',
}) {
  // todo: imageTitle and image subtitle are not longer valid. find what replaced.
  const { href, blank } = content; // , imagetitle, imagesubtitle
  const {
    altText, caption, headline, source, url,
  } = content.image;
  const sourceText = (source?.name) || '';
  const copyrightText = (source?.copyright) || '';
  const alt = `Image::${altText}|${sourceText}${copyrightText ? `|${copyrightText}` : ''}`;

  const pic = (
    <div
      className={classNames(
        'image-liftout__overlay layout-grid-item--with-gutter-s-only',
        {
          'image-liftout__overlay--opacity-title': headline.primary,
        },
      )}
    >
      <Picture
        responsiveFlavors={{
          s: AIMS_FLAVORS.FOCAL_320x160,
          m: AIMS_FLAVORS.FOCAL_678x339,
          l: AIMS_FLAVORS.FOCAL_920x460,
          xl: AIMS_FLAVORS.FOCAL_1160x580,
        }}
        alt={alt}
        url={url.primary}
        className="image-liftout__image"
        lazyloaded={lazyloaded}
      />
      <div className="image-liftout__label-wrapper">
        <span className="image-liftout__label">{caption}</span>
      </div>
    </div>
  );

  return (
    <figure className="image-liftout" id={elementId ? `anchor-${elementId}` : undefined}>
      {href ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={href}
          target={blank ? '_blank' : null}
          rel={blank ? 'noreferrer' : null}
          title={caption}
        >
          {pic}
        </a>
      ) : (
        pic
      )}
      <Caption
        image={content.image}
        className="image-liftout__caption layout-grid-item--with-gutter-s-only"
      />
    </figure>
  );
}

export { ImageLiftout };

ImageLiftout.propTypes = {
  content: imagePropTypes.isRequired,
  lazyloaded: PropTypes.bool,
  elementId: PropTypes.string,
};

