import React from 'react';

import { widget as widgetPropType } from 'lib/CustomPropTypes';
import { decodeString } from 'lib/stringUtils';

import Related from 'components/Related';

const FeatureLink = ({
  widget = {},
}) => {
  const {
    'featuredlink-headline': headline,
    'featuredlink-label': label,
    'featuredlink-linktext': text,
    'featuredlink-image': image,
    'featuredlink-linkurl': url,
  } = widget.properties;

  const items = [{
    url,
    label: decodeString(label),
    title: decodeString(headline || text),
    thumbnail: image,
  }];

  return (
    <Related
      title="Related"
      elementId="related"
      linkTracking="related"
      items={items}
    />
  );
};

FeatureLink.propTypes = {
  widget: widgetPropType,
};


export default FeatureLink;
