import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// TODO: [BENTO-12233] Temporarily leaving styles.module.scss to handle grid styles.
// Will need refactor when solution for grid is implemented within new theming strategy
import { getDataActivityMapID } from 'lib/articleUtils';
import styles from './styles.module.scss';

const block = 'listicleProduct';


const Container = ({
  children = null,
  isLarge = false,
  productName = '',
}) => {
  const dataActivityMapID = getDataActivityMapID(
    {
      componentName: 'listicle-product',
      pageRegion: 'article-body',
      componentTitle: productName,
    },
  );
  return (
    <div
      className={classNames(
        `${block}`,
        'dn-print mv8',
        {
          [styles.gridContainerLarge]: isLarge,
          'listicle-product__grid-container--large': isLarge,
          'listicle-product__grid-container--small': !isLarge,
        },
      )}
      data-activity-map={dataActivityMapID}
      data-test="listicle-product-container"
      data-testid="listicle-product-container"
    >
      <div
        className="listicle-product__grid-row"
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  isLarge: PropTypes.bool,
  productName: PropTypes.string,
};


export { Container };
