import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

/**
 * Subhed renders a subheading (h3) with the provided text.
 * If no text is provided, it returns null and nothing is rendered.
 *
 * @component
 * @param {object} props - The properties that define the Subhed component.
 * @param {string} props.children - The text of the subheading. Defaults to an empty string.
 *
 * @example
 * <Subhed text="This is a subheading." />
 *
 * @returns {React.Element} The rendered Subhed component, or null if no text is provided.
 */

export function Subhed({
  children = '',
}) {
  if (!children) return null;
  return (
    <h3 className={styles.stepSubhed}>{children}</h3>
  );
}

Subhed.propTypes = {
  children: PropTypes.string,
};

