import get from 'lodash.get';
import Breakpoints from 'lib/Breakpoints';
import getConfig from 'next/config';

import { TAX_CONSTANTS } from 'lib/taxonomy';

const ASPECT_1X1 = 'ASPECT_1X1';
const ASPECT_2X1 = 'ASPECT_2X1';
const {
  SELECT,
  THINK,
  TODAY,
  TODAY: {
    GIFT_GUIDE: GIFT_GUIDE_TAX_PATH,
  },
} = TAX_CONSTANTS;

const getLimit = (props) => {
  const {
    isGiftGuide,
    isTrending,
    isNBCNewsCommerce,
    isBetterNewsCommerce,
    isValidNonEcommerceTodayArticle,
    isSelect,
  } = props;

  if (isNBCNewsCommerce
    || (!isValidNonEcommerceTodayArticle && isTrending)
    || isGiftGuide
    || isBetterNewsCommerce
    || isSelect
  ) {
    return 4;
  }

  if (isValidNonEcommerceTodayArticle) {
    return 3;
  }

  return Breakpoints.isS() ? 1 : 2;
};

/**
 * Formatter function for getFormattedItems
 */
export const formatRecommendationItem = (item) => {
  const { id, type } = item;
  const alternateTeaseImages = get(item, ['alternateTeaseImages']) || [];
  const thumbnail1x1 = alternateTeaseImages.find(
    ({ aspectRatio }) => aspectRatio === ASPECT_1X1,
  );
  const thumbnail2x1 = alternateTeaseImages.find(
    ({ aspectRatio }) => aspectRatio === ASPECT_2X1,
  );

  const primaryThumbnail = get(thumbnail1x1, ['image', 'url', 'primary'], null);
  const secondaryThumbnail = get(thumbnail2x1, ['image', 'url', 'primary'], null);
  const defaultThumbnail = get(item, ['primaryImage', 'url', 'primary']);
  const thumbnail = primaryThumbnail || secondaryThumbnail || defaultThumbnail;

  const unibrow = {
    url: get(item, ['unibrow', 'url']),
    text: get(item, ['unibrow', 'text']),
  };
  const title = get(item, ['headline', 'tease']);
  const url = get(item, ['url', 'primary']);

  return {
    id,
    url,
    type,
    title,
    unibrow,
    thumbnail,
  };
};

const formatPayload = (payload) => {
  const items = payload?.data?.search?.items ?? [];

  if (!items.length) {
    // eslint-disable-next-line no-console
    console.warn('RamenRecommendedComponent: No valid items returned from bentoAPI');
  }

  return items.map(formatRecommendationItem);
};

export const formatCurationPayload = (payload, articleID) => {
  const layouts = payload?.data?.curation?.layouts ?? [];

  const unformattedItems = layouts
    .reduce((pkgs, l) => [...l.packages, ...pkgs], [])
    .filter((pkg) => pkg?.name?.toLowerCase()?.startsWith('shop on the show'))
    .reduce((items, pkg) => [...items, ...pkg.items], [])
    .filter((i) => i.id !== articleID)
    .map((i) => ({ ...i, ...i.item }))
    .slice(0, 4);
  return unformattedItems.map(formatRecommendationItem);
};

function getTaxonomy(path) {
  const {
    SECTION_STYLE,
    SECTION_FOOD,
    SECTION_PETS,
    SECTION_HOME,
    SECTION_TMRW_TODAY,
    SECTION_ABOUT,
    SECTION_INFORMATION,
    SECTION_PODCASTS,
    SECTION_GAMES,
    SECTION_MONEY,
    SECTION_HEALTH,
    SECTION_LIFE,
    SECTION_KINDNESS,
    SECTION_PAID_CONTENT,
    SECTION_PARENTING_GUIDES,
    SECTION_PARENTS,
    SECTION_POPCULTURE,
    SECTION_SEASON_OF_KINDNESS,
    SECTION_TOGETHER,
    SECTION_VISIT_TODAY,
    SHOP_STYLE_TOPIC,
    SHOP_BEAUTY_TOPIC,
    SHOP_BESTSELLERS_LABEL,
    SHOP_DEALS_LABEL,
    SHOP_CLOTHING_LABEL,
    SHOP_KITCHEN_TOPIC,
    SHOP_FOOD_GROCERIES_LABEL,
    SHOP_COUNTER_TOP_APPLIANCES_LABEL,
    SHOP_PET_SUPPLIES_LABEL,
    SHOP_TECH_TOPIC,
    SHOP_BEDDING_LABEL,
    SHOP_WELLNESS_LABEL,
    SHOP_STORAGE_ORGANIZATION_LABEL,
    SHOP_CELEBRITY_FAVORITES_LABEL,
    SHOP_AMAZON_PRIME_DAY_TOPIC,
    SHOP_BLACK_FRIDAY_DEALS_TOPIC,
    SHOP_LIFESTYLE_FITNESS_TOPIC,
    SHOP_AMAZON_BESTSELLERS_TOPIC,
    SHOP_BESTSELLERS_TOPIC,
    SHOP_GIFT_GUIDES_TOPIC,
    SHOP_BEAUTY_PRODUCTS_TOPIC,
    SHOP_KIDS_BABIES_TOPIC,
    SHOP_FASHION_TOPIC,
    SHOP_CONSUMER_REPORTS_TOPIC,
    SHOP_TECH_GADGETS_TOPIC,
    SHOP_TIKTOK_TOPIC,
    SHOP_ALL_DAY_TOPIC,
    SHOP_POP_UP_SHOP_TOPIC,
    SHOP_TODAY_HOLIDAY_PLAZA_TOPIC,
  } = TODAY;

  switch (path) {
    case SECTION_STYLE:
      return `
        taxonomy: ${SHOP_STYLE_TOPIC}
        OR taxonomy: ${SHOP_BEAUTY_TOPIC}
        OR taxonomy: ${SHOP_BESTSELLERS_LABEL}
        OR taxonomy: ${SHOP_DEALS_LABEL}
        OR taxonomy: ${SHOP_CLOTHING_LABEL}
        OR taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
      `;

    case SECTION_FOOD:
      return `
        taxonomy: ${SHOP_KITCHEN_TOPIC}
        OR taxonomy:${SHOP_FOOD_GROCERIES_LABEL}
        OR taxonomy: ${SHOP_COUNTER_TOP_APPLIANCES_LABEL}
      `;

    case SECTION_PETS:
      return `
        taxonomy: ${SHOP_PET_SUPPLIES_LABEL}
        OR taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
      `;

    case SECTION_HOME:
      return `
        taxonomy: ${SHOP_KITCHEN_TOPIC}
        OR taxonomy: ${SHOP_TECH_TOPIC}
        OR taxonomy: ${SHOP_BEDDING_LABEL}
        OR taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
      `;
    case SECTION_TMRW_TODAY:
      return `
        taxonomy: ${SHOP_WELLNESS_LABEL}
        OR taxonomy: ${SHOP_STORAGE_ORGANIZATION_LABEL}
        OR taxonomy: ${SHOP_STYLE_TOPIC}
        OR taxonomy: ${SHOP_BEAUTY_TOPIC}
        OR taxonomy: ${SHOP_CELEBRITY_FAVORITES_LABEL}
        OR taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_BESTSELLERS_TOPIC}
      `;

    case SECTION_ABOUT:
    case SECTION_INFORMATION:
    case SECTION_PODCASTS:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_AMAZON_BESTSELLERS_TOPIC}
      `;
    case SECTION_GAMES:
    case SECTION_MONEY:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_TECH_GADGETS_TOPIC}
      `;
    case SECTION_HEALTH:
    case SECTION_LIFE:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_LIFESTYLE_FITNESS_TOPIC}
      `;
    case SECTION_PAID_CONTENT:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_CONSUMER_REPORTS_TOPIC}
        OR taxonomy: ${SHOP_AMAZON_BESTSELLERS_TOPIC}
      `;
    case SECTION_PARENTING_GUIDES:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_KIDS_BABIES_TOPIC}
      `;
    case SECTION_PARENTS:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_KIDS_BABIES_TOPIC}
        OR taxonomy: ${SHOP_GIFT_GUIDES_TOPIC}
      `;
    case SECTION_POPCULTURE:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_BEAUTY_PRODUCTS_TOPIC}
        OR taxonomy: ${SHOP_FASHION_TOPIC}
        OR taxonomy: ${SHOP_BESTSELLERS_TOPIC}
        OR taxonomy: ${SHOP_TIKTOK_TOPIC}
      `;
    case SECTION_SEASON_OF_KINDNESS:
    case SECTION_KINDNESS:
    case SECTION_TOGETHER:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_GIFT_GUIDES_TOPIC}
      `;
    case SECTION_VISIT_TODAY:
      return `
        taxonomy: ${SHOP_AMAZON_PRIME_DAY_TOPIC}
        OR taxonomy: ${SHOP_BLACK_FRIDAY_DEALS_TOPIC}
        OR taxonomy: ${SHOP_ALL_DAY_TOPIC}
        OR taxonomy: ${SHOP_POP_UP_SHOP_TOPIC}
        OR taxonomy: ${SHOP_TODAY_HOLIDAY_PLAZA_TOPIC}
      `;

    default:
      return `taxonomy: ${path}`;
  }
}

function resolveTaxonomyFilter(props) {
  const {
    isGiftGuide,
    taxonomy: {
      primaryVertical = {},
      primaryTopic = {},
      primarySection = {},
      topics: taxonomyTopics = [],
    } = {},
  } = props;

  const primaryVerticalPath = primaryVertical?.path;
  const primaryTopicPath = primaryTopic?.path ?? GIFT_GUIDE_TAX_PATH;
  let primarySectionPath = primarySection?.path;

  /** @type {string} */
  let taxonomyFilter;

  if (primaryVerticalPath === SELECT.VERTICAL) {
    // Select vertical conditions
    const topics = taxonomyTopics ?? [];

    const hasTopicPrimeDay = topics.some(
      (topic) => (topic?.path ?? '') === SELECT.SECTION_PRIME_DAY,
    );

    taxonomyFilter = hasTopicPrimeDay
      ? `taxonomy: ${SELECT.SECTION_PRIME_DAY}`
      : `taxonomy: ${primaryTopicPath}|primary`;
  } else if (primaryVerticalPath === THINK.VERTICAL) {
    // THINK Vertical does not have have a Primary Section taxonomy [BENTO-24274].
    // Query Vertical taxonomy:
    taxonomyFilter = `taxonomy: ${THINK.VERTICAL}`;
  } else if (primarySectionPath === undefined && primaryVerticalPath !== undefined) {
    taxonomyFilter = getTaxonomy(primaryVerticalPath);
  } else if (primarySectionPath === undefined && primaryVerticalPath === undefined) {
    primarySectionPath = GIFT_GUIDE_TAX_PATH;
    taxonomyFilter = getTaxonomy(primarySectionPath);
  } else { // All other verticals, query based on Section taxonomy
    taxonomyFilter = getTaxonomy(primarySectionPath);
  }

  if (isGiftGuide) {
    taxonomyFilter = `taxonomy: ${GIFT_GUIDE_TAX_PATH}|primary`;
  }

  return taxonomyFilter;
}

export const getApiEndpoint = (props) => {
  const {
    articleID,
    isRightRail,
    taxonomy: {
      primaryTopic = {},
    } = {},
    isShopTheShow,
  } = props;

  const { publicRuntimeConfig: { API_URL_BENTO_PUBLIC } } = getConfig();

  if (isShopTheShow) {
    return `${API_URL_BENTO_PUBLIC}/curation/taxonomy-terms/today/-1/on-the-show`;
  }

  const taxonomyFilter = resolveTaxonomyFilter(props);
  const size = getLimit(props);
  const primaryTopicPath = primaryTopic?.path ?? GIFT_GUIDE_TAX_PATH;
  // For gift guide components that are NOT on gift guide articles, we can safely fetch the
  // first page, since there is no risk of conflict with the right rail items.
  const page = isRightRail || primaryTopicPath !== GIFT_GUIDE_TAX_PATH ? 1 : 2;
  const query = `type:article AND NOT id:${articleID} AND autoCuration:true AND (${taxonomyFilter})`;
  const apiEndpoint = `${API_URL_BENTO_PUBLIC}/commerceRecommended?query=${query}&page=${page}&size=${size}&sort=datePublished:desc`;
  return apiEndpoint;
};

/**
 *
 * @param {object} props
 * @param {string} props.articleID
 * @param {boolean} props.isRightRail
 * @param {TaxonomyCollection} props.taxonomy
 * @param {Function} setItems
 */
export const fetchAndSetFormattedItems = (props, setItems) => {
  const {
    articleID,
    isShopTheShow,
  } = props;

  const apiEndpoint = getApiEndpoint(props);

  if (isShopTheShow) {
    fetch(apiEndpoint)
      .then((res) => res.json())
      .then((payload) => formatCurationPayload(payload, articleID))
      .then(setItems)
      // eslint-disable-next-line no-console
      .catch(console.error);
  } else {
    fetch(apiEndpoint)
      .then((res) => res.json())
      .then(formatPayload)
      .then(setItems)
      // eslint-disable-next-line no-console
      .catch(console.error);
  }
};

/**
 * Certain article types do not get gift guide components, and small screens do not have the room.
 */
export const shouldShowRelatedContent = (props) => {
  const {
    mobile,
    isTrending,
    isGiftGuide,
    isShopTheShow,
    isNBCNewsCommerce,
    isBetterNewsCommerce,
    isValidNonEcommerceTodayArticle,
    isSelect,
  } = props;
  if (
    isTrending
    || isShopTheShow
    || isGiftGuide
    || isNBCNewsCommerce
    || isBetterNewsCommerce
    || isValidNonEcommerceTodayArticle
    || isSelect
  ) {
    return true;
  }
  return (mobile && Breakpoints.isSorM()) || (!mobile && Breakpoints.isLorXL());
};
