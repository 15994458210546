import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

export function ReportUnmounting({
  children = undefined,
  onUnmount = undefined,
}) {
  useLayoutEffect(() => () => {
    onUnmount?.();
  }, []);
  return children;
}

ReportUnmounting.propTypes = {
  children: PropTypes.node,
  onUnmount: PropTypes.func,
};

