import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const ProsCons = ({
  pros = { label: null, items: [] },
  cons = { label: null, items: [] },
  dataActivityMapId,
  isOde = false,
}) => (
  <div
    className={classNames(styles.prosConsContainer, { [styles.isOde]: isOde })}
    data-testid="listicle-product__pros-cons-container"
    data-activity-map={dataActivityMapId}
  >
    <div className={styles.prosConsChild}>
      <div className={styles.prosConsLabel}>
        {pros.label || i18next.t('What we like')}
      </div>
      <ul className={styles.prosConsItems}>
        {pros.items?.slice(0, 3).map((pro) => (
          <li key={pro} dangerouslySetInnerHTML={{ __html: pro }} />
        ))}
      </ul>
    </div>
    <div className={styles.prosConsChild}>
      <div className={styles.prosConsLabel}>
        {cons.label || i18next.t('Something to note')}
      </div>
      {cons.items?.length > 0 && (
        <ul className={styles.prosConsItems}>
          {cons.items.slice(0, 3).map((con) => (
            <li key={con} dangerouslySetInnerHTML={{ __html: con }} />
          ))}
        </ul>
      )}
      {!(cons.items?.length > 0) && (
        <div className={styles.prosConsFallback}>
          {i18next.t('Nothing to note at this time')}
        </div>
      )}
    </div>
  </div>
);

const proConsShape = PropTypes.shape({
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
});

ProsCons.propTypes = {
  pros: proConsShape,
  cons: proConsShape,
  dataActivityMapId: PropTypes.string.isRequired,
  isOde: PropTypes.bool,
};
