import React from 'react';
import PropTypes from 'prop-types';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import {
  author as AuthorPropType,
  unibrow as UnibrowPropType,
} from 'lib/CustomPropTypes';

import { BylineList } from 'components/Byline';
import Link from 'components/Link';
import { Picture } from 'components/Picture';
import { Unibrow } from 'components/Unibrow';
import TypeIcon from 'components/TypeIcon';

import styles from './styles.module.scss';

function Item({
  headline,
  unibrow,
  url,
  authors = [],
  publicationName = null,
  tease = null,
  type = null,
  icid = null,
}) {
  const isCustomLink = type === 'custom';
  const target = isCustomLink ? '_blank' : '_top';

  const preview = type === 'video' ? { url, redirect: true } : false;

  return (
    <>
      {tease && (
        <div className={styles.tease}>
          <Link target={target} to={url} icid={icid}>
            <Picture
              preview={preview}
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_80x80,
              }}
              retina
              url={tease}
            />
            <TypeIcon type={type} />
          </Link>
        </div>
      )}
      <div className={styles.info}>
        <Unibrow
          className={styles.unibrow}
          size="h4"
          target={target}
          unibrow={unibrow}
        />
        <h4 className={styles.headline}>
          <Link target={target} to={url} icid={icid}>
            {headline}
          </Link>
        </h4>
        {isCustomLink && (
          <Link
            className={styles.externalLink}
            target={target}
            to={url}
            icid={icid}
          >
            {publicationName}
            <span className="icon icon-external-link-arrow" />
          </Link>
        )}
        <div className={styles.author}>
          <BylineList
            authors={authors}
          />
        </div>
      </div>
    </>
  );
}

Item.propTypes = {
  authors: PropTypes.arrayOf(AuthorPropType),
  headline: PropTypes.string.isRequired,
  publicationName: PropTypes.string,
  tease: PropTypes.string,
  type: PropTypes.string,
  unibrow: UnibrowPropType.isRequired,
  url: PropTypes.string.isRequired,
  icid: PropTypes.string,
};


export { Item };
