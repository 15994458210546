import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { stub as $t } from '@nbcnews/analytics-framework';
import { useFeatureFlagContext, ArticleContext } from 'lib/ContextTypes';
import ContentTimestamp from 'components/ContentTimestamp';
import { Border } from 'components/MsnbcDaily/Border';
import { StripePattern } from 'components/StripePattern';
import styles from './styles.module.scss';

/**
 * @typedef {Object} ContentModuleProps
 *
 * @property {string} [activityMapId]
 *
 * @property {function} htmlElement - Function that parses the markup array
 *
 * @property {string} [icid]
 *
 * @property {Object} item
 * @property {Object} item.contentModule
 * @property {Object} item.contentModule.content
 * @property {Array} item.contentModule.content.markupAndEmbeds
 * @property {Object} item.date
 * @property {DateTime} item.date.createdAt
 * @property {DateTime} item.date.modifiedAt
 * @property {DateTime} item.date.publishedAt
 * @property {Object} [item.description]
 * @property {string} [item.description.tease]
 * @property {Object} [item.headline]
 * @property {string} [item.headline.tease]
 *
 * @property {string} path
 *
 * @property {string} vertical
 *
 * @param {ContentModuleProps} props
 */

$t('register', 'content_embed_inview');

export const ContentModule = ({
  activityMapId = '',
  htmlElement,
  icid = '',
  item: {
    contentModule,
  } = {},
  path,
  vertical,
}) => {
  if (!contentModule) {
    return null;
  }

  const {
    content: {
      markupAndEmbeds = [],
    } = {},
    date = { createdAt: '', modifiedAt: '', publishedAt: '' },
    description = { tease: '' },
    headline = { tease: '' },
  } = contentModule;


  const {
    'embedded-content-module': FeatureFlag,
  } = useFeatureFlagContext();

  const { subType } = useContext(ArticleContext);
  const isNews = vertical === 'news';
  const isMSNBC = vertical === 'msnbc';
  const isToday = vertical === 'today';
  const isTelemundo = vertical === 'telemundo';
  const isLiveBlog = subType === 'liveBlog' && isNews;

  // Fires analytics tracking with the component is in view
  const { ref } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        $t('track', 'content_embed_inview', {
          placement: 'body',
        });
      }
    },
  });

  if (!FeatureFlag || isTelemundo) {
    return null;
  }

  const markup = markupAndEmbeds?.map((element, i) => {
    const { html } = element;

    // Add icid attribute
    if (html.includes('<a ')) {
      const addIcid = html.replace(/(<a )/g, `$1data-icid="${icid}"`);
      const elementWithIcid = { ...element, html: addIcid };
      return htmlElement(elementWithIcid, i, path);
    }

    return htmlElement(element, i, path);
  });

  const brandStyles = classNames(
    styles.contentModule,
    {
      [styles.news]: isNews,
      [styles.msnbc]: isMSNBC,
      [styles.today]: isToday,
      [styles.liveBlog]: isLiveBlog,
    },
  );

  return (
    <section
      className={brandStyles}
      data-activity-map={activityMapId}
      ref={ref}
    >

      {isLiveBlog && (
        <StripePattern pattern={{ height: 2, width: 4 }} />
      )}

      {isMSNBC && (
        <Border />
      )}

      {(headline?.tease || description?.tease) && (
        <header className={styles.header}>

          {headline?.tease && (
            <h2 className={styles.headline}>
              {headline.tease}
            </h2>
          )}

          {description?.tease && (
            <p className={styles.description}>
              {description?.tease}
            </p>
          )}

        </header>
      )}

      {markup}

      {date?.modifiedAt && (
        <footer className={styles.footer}>
          <ContentTimestamp
            datePublished={date?.modifiedAt}
            delimiter=", "
            hideMicroData
            meta
            styles={styles.timestamp}
            timeZone="America/New_York"
          />
        </footer>
      )}

    </section>
  );
};

ContentModule.propTypes = {
  activityMapId: PropTypes.string,
  icid: PropTypes.string,
  item: PropTypes.shape({
    contentModule: PropTypes.shape({
      content: PropTypes.shape({
        markupAndEmbeds: PropTypes.arrayOf(PropTypes.object.isRequired),
      }),
      description: PropTypes.shape({
        tease: PropTypes.string,
      }),
      headline: PropTypes.shape({
        tease: PropTypes.string,
      }),
      date: PropTypes.shape({
        createdAt: PropTypes.instanceOf(Date).isRequired,
        modifiedAt: PropTypes.instanceOf(Date).isRequired,
        publishedAt: PropTypes.instanceOf(Date).isRequired,
      }),
    }),
  }),
  htmlElement: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
};

export default ContentModule;
