/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

/**
 * Render the control component or one of the experiment variants based on the
 * _vis_opt_exp_<SOME_VWO_EXPERIMENT_CAMPAIGN_ID>_combi cookie value. Fallback to
 * rendering nothing for misconfigured experiments
 * @param {object} props
 * @param {string} props.campaign ID of the VWO Campaign
 * @param {JSX.Element} props.children Control scenario
 * @param {Array.<JSX.Element>}  props.variantComponents List of variant components
 * @param {string} props.cookieValue Manually set the cookie value; useful for staging and testing
 * @returns {?JSX.Element}
 */
function RunExperiment(props) {
  const {
    campaignNumber,
    children,
    variantComponents,
    cookieValue,
  } = props;

  // This cookie is created when a visitor is chosen for a
  // particular variation for a test. This cookie makes sure
  // that visitors see the same variation when they revisit
  // the page in the future. Control has a value of 1,
  // Variation #1 has a value of 2, Variation #2 has a value
  // of 3, and so on
  const experimentCookieName = `_vis_opt_exp_${campaignNumber}_combi`;

  const [cookies] = useCookies([experimentCookieName]);

  // Track whether we're rendering on the client.  This is because we always want to render the
  // "Control" experience on the server as well as the first client render.  We do this because
  // the VWO cookie can't be relied upon server-side due to caching policy.  As a result, even
  // first client render has to render the default experience for a split second so that there
  // isn't a hydration mismatch
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);

  let experimentIdentifier;
  if (isClient) {
    experimentIdentifier = cookieValue || cookies[experimentCookieName];
  }

  const selectedExperiment = getSelectedExperiment(experimentIdentifier, variantComponents);

  return selectedExperiment === 'children' ? children : selectedExperiment;
}

/**
 * @template {TExperiment} TExperiment
 * @param {string | undefined} experimentIdentifier
 * @param {TExperiment[]} experiments
 * @returns {TExperiment | 'children' | null}
 */
export function getSelectedExperiment(experimentIdentifier, experiments) {
  if (!experimentIdentifier) {
    // Not all pages participate in the experiment, those that are not should see the
    // control component. Example: Newsletter module has an experiment running on certain
    // page paths. Other places the newlsetter module renders would expect to see the usual
    // component, paths participating in the experiment will always have an experiment identifier
    return 'children';
  }

  const experimentNumber = parseInt(experimentIdentifier, 10);

  // Control component is always experiment number 1
  const shouldControlComponentRender = experimentNumber === 1;
  if (shouldControlComponentRender) {
    return 'children';
  }

  // VWO variant experiments begin starting at value 2
  const variantComponentIndex = experimentNumber - 2;

  const variantComponent = experiments[variantComponentIndex];

  const shouldNullRender = variantComponent === undefined;
  if (shouldNullRender) {
    return null;
  }

  return variantComponent;
}

RunExperiment.propTypes = {
  campaignNumber: PropTypes.number,
  children: PropTypes.element.isRequired,
  variantComponents: PropTypes.arrayOf(
    PropTypes.element,
  ).isRequired,
};

export { RunExperiment };
