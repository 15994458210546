import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Picture } from 'components/Picture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import styles from './promotionalStyles.module.scss';

const PromotionalMedia = ({
  media,
  selectMedia = () => {},
  additionalStyles = {},
  selectedIdx = -1,
  isMobile = false,
}) => (
  <div className="df justify-start">
    <ul className={classNames(styles.mediaContainer, additionalStyles.container)}>
      {media.map(({ url }, idx) => (
        <li
          className={classNames(
            styles.mediaItem,
            additionalStyles.item,
            { [styles.mobile]: isMobile },
          )}
          data-test="promotional-media-item"
          data-testid="promotional-media-item"
          key={url.primary}
        >
          <button
            type="button"
            onClick={() => selectMedia(url.primary, idx)}
            className={classNames({ [styles.selected]: idx === selectedIdx }, 'css-reset-btn')}
          >
            <Picture
              url={url.primary}
              responsiveFlavors={{ s: AIMS_FLAVORS.FOCAL_260x260 }}
              flavor="focal"
            />
          </button>
        </li>
      ))}
    </ul>
  </div>
);

PromotionalMedia.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.shape({ primary: PropTypes.string }),
    }),
  ).isRequired,
  selectMedia: PropTypes.func,
  additionalStyles: PropTypes.shape({
    container: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.bool,
    ]),
    item: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.bool,
    ]),
  }),
  selectedIdx: PropTypes.number,
  isMobile: PropTypes.bool,
};

export { PromotionalMedia };
