import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';
import { NorthForkScript } from 'components/NorthForkScript';
import { Bacon } from 'components/packages/Bacon';
import ArticlePropTypes from 'lib/CustomPropTypes/article';
import { getProductOfferIdsFromProducts } from 'lib/productOfferPriceHelpers';
import { SHOP_LIST_BY_ONE_PRODUCT } from 'lib/constants';
import './styles.themed.scss';
import styles from './styles.module.scss';

$t('register', 'mbt_bacon_scroll');

export function ShoppingCart({
  article: {
    id: articleId,
    body,
    ecommerceMetadata: {
      trackingId,
      shoppingListTitle = '',
    } = {},
  },
  vertical,
  isEmbed = false,
}) {
  React.useEffect(() => {
    // Send a window message to the parent, AMP in this case, to aid in resizing the package
    // within the iFrame. I used an asterisk for the target origin because we don't know
    // where the other window's document will be located. This is safe for us because
    // the only data we are sending is the scrollHeight, nothing malicious can be done with this.
    requestAnimationFrame(() => {
      window.parent.postMessage(
        {
          sentinel: 'amp',
          type: 'embed-size',
          height: document.body.scrollHeight > 100 ? `${document.body.scrollHeight}px` : '555px',
        },
        '*',
      );
    });
  });

  const items = body
    .filter(({ type }) => type === 'embeddedProduct')
    .map(({ product }) => ({
      item: product,
      computedValues: {
        headline: product.name,
        teaseImage: product.teaseImage,
        url: null,
      },
    }));

  const productIds = getProductOfferIdsFromProducts(items);

  const [isScrollTracked, setScrollTracked] = useState(false);

  const handleBaconOnScroll = (_, { x, width }) => {
    if (!isScrollTracked && (x / width) > 0.5) {
      $t('track', 'mbt_bacon_scroll', {
        action: 'scroll',
      });
      setScrollTracked(true);
    }
  };

  return (
    <div className={`no-slanted-bg ${styles.container}`} data-test-id="shopping-cart">
      {!isEmbed && (
        <>
          <NorthForkScript />
          <div
            className="nfw-anchor"
            data-article-page="true"
            data-widget="nongrocery-cart"
            data-article-products={productIds.join(',')}
            data-test="shopping-cart__north-fork-script"
          />
        </>
      )}
      <Bacon
        content={{
          subType: SHOP_LIST_BY_ONE_PRODUCT,
          metadata: {
            title: shoppingListTitle,
            disclaimerKey: 'TARGET_SHOP_THIS_LIST',
            trackingId: `${articleId}cart${trackingId || ''}`,
          },
          items,
        }}
        onScroll={handleBaconOnScroll}
        pageRegion="article-body"
        vertical={vertical}
        useAltTitle={vertical === 'today'}
        titleSize="small"
        titleBgColor="green"
      />
    </div>
  );
}

ShoppingCart.propTypes = {
  vertical: PropTypes.string.isRequired,
  article: ArticlePropTypes.isRequired,
  isEmbed: PropTypes.bool,
};


export default ShoppingCart;
