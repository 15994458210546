/**
 * Sets the title attribute for all iframe elements in the provided HTML string.
 *
 * @param {string} html - The HTML string containing iframe elements.
 * @param {string} [siteName] - The name of the site to include in the title. If the site name is 'twitter' or not provided, it will be omitted.
 * @param {string} [headline] - The headline to include in the title. If not provided, it will be omitted.
 * @returns {string} - The modified HTML string with the title attribute set for all iframe elements.
 */
function htmlSetIframeTitle(html, siteName, headline) {
  const computedName = siteName?.toLowerCase() === 'twitter' || !siteName ? '' : `${siteName}, `;
  const title = `${computedName}${headline ?? ''}`.trim() || 'Inline Frame';
  return html.replace(/<iframe/g, `<iframe title="${title}"`);
}

export {
  htmlSetIframeTitle,
};

