import React from 'react';
import PropTypes from 'prop-types';
import './styles.themed.scss';

const className = 'summary-box';

function SummaryBox({
  headline,
  items,
  vertical,
  elementId = null,
}) {
  const showWithoutHeadline = {
    better: true,
    news: true,
    select: true,
    think: true,
  };

  if (!items || (!headline?.length && !showWithoutHeadline[vertical])) {
    return null;
  }

  const listItems = items.map((item) => (
    <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
  ));

  return (
    <div
      className={className}
      data-testid="summary-box"
      id={elementId && `anchor-${elementId}`}
    >
      <div className={`${className}__content`}>
        {!!headline?.length && (
          <h2 className={`${className}__headline`}>
            {headline}
          </h2>
        )}
        <ul className={`${className}__items`}>
          {listItems}
        </ul>
      </div>
    </div>
  );
}

SummaryBox.propTypes = {
  elementId: PropTypes.string,
  headline: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  vertical: PropTypes.string.isRequired,
};


export default SummaryBox;
