import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { decodeString } from 'lib/stringUtils';

import './styles.themed.scss';

const block = 'pullquote';

function PullQuote({
  text,
  attribution = null,
  elementId = null,
  quip = null,
  size = 'small',
}) {
  if (!text) {
    return null;
  }

  return (
    <div
      id={elementId && `anchor-${elementId}`}
      className={classNames(
        block,
        {
          [`${block}--small`]: size === 'small',
          [`${block}--large`]: size === 'large',
        },
      )}
      data-testid={`${block}-${size}`}
    >
      <p className={`${block}__quote`} data-testid={`${block}-quote`}>
        {decodeString(text)}
      </p>
      {(attribution || quip) && (
        <p className={`${block}__attribution`}>
          <cite className={`${block}__cite`} data-testid={`${block}-attribution`}>
            {decodeString(attribution)}
          </cite>
          {(attribution && quip) && (
            // add non-breaking space when there's attribution & quip
            <span>&nbsp;</span>
          )}
          <span className={`${block}__quip`} data-testid="pullquote-quip">
            {decodeString(quip)}
          </span>
        </p>
      )}
    </div>
  );
}

PullQuote.propTypes = {
  attribution: PropTypes.string,
  elementId: PropTypes.string,
  quip: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string.isRequired,
};


export default PullQuote;
