import React from 'react';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link/LinkIfHref';
import './styles.themed.scss';

import {
  decodeString,
  generateElementKey,
} from 'lib/stringUtils';

const block = 'blockquote';
/**
* BlockQuote component renders a blockquote element with optional attribution and source.
*
* @param {object} props - The properties object.
* @param {string} props.attribution - The attribution text for the quote.
* @param {string} props.source - The source URL for the quote.
* @param {string} props.text - The text content of the quote.
* @param {string} props.elementId - The optional element ID for the blockquote section.
* @returns {React.Element} The rendered BlockQuote component.
*/
function BlockQuote({
  attribution = null,
  source = null,
  text = '',
  elementId = null,
}) {
  return (
    <section className={block} id={elementId && `anchor-${elementId}`}>
      <blockquote
        className={`${block}__quote`}
        cite={attribution}
      >
        {decodeString(text).split(/[\n\r]+/).map((graph) => (
          <p key={generateElementKey(graph)}>
            {graph}
          </p>
        ))}
        <small className={`${block}__source`}>
          <LinkIfHref
            data-test="blockquote-source"
            href={source}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span data-test="blockquote-attribution">
              {decodeString(attribution)}
            </span>
          </LinkIfHref>
        </small>
      </blockquote>
    </section>
  );
}

BlockQuote.propTypes = {
  attribution: PropTypes.string,
  source: PropTypes.string,
  text: PropTypes.string.isRequired,
  elementId: PropTypes.string,
};

export default BlockQuote;
