import classNames from 'classnames';
import React, { Component } from 'react';
import get from 'lodash.get';
import LazyLoad from 'react-lazyload';

import loadScript from 'lib/loadScript';
import embeddedWidgetProps from 'lib/CustomPropTypes/embeddedWidget';
import htmlSetIframeLoadingAttributes from 'lib/htmlSetIframeLoadingAttributes';
import { htmlSetIframeDataSrcAttributes } from 'lib/htmlSetIframeDataSrcAttributes';
import { htmlSetIframeTitle } from 'lib/htmlSetIframeTitle';
import { ArticleContext } from 'lib/ContextTypes';
import Breakpoints from 'lib/Breakpoints';
import { FacebookEmbed } from './FacebookEmbed';
import { ReportUnmounting } from '../../ReportUnmounting';

export const {
  LAMBDA_TEST = false,
} = process.env;

export class IframelyEmbed extends Component {
  static propTypes = embeddedWidgetProps.isRequired;

  static contextType = ArticleContext;

  constructor(props) {
    super(props);
    this.iframeWrapper = React.createRef();
    this.state = {
      lastReportedHeight: 0,
      isMobileOrTabletBreakpoint: true,
    };
  }

  componentDidMount() {
    // Iframely's embed.js script should be loaded once for all embeds
    loadScript('https://iframe.nbcnews.com/embed.js');

    this.setState({
      isMobileOrTabletBreakpoint: Breakpoints.isSorM(),
    });
  }

  onLazyLoadUnmounting() {
    const height = this.iframeWrapper?.current?.offsetHeight;
    return height ? this.setState({ lastReportedHeight: height })
      : null;
  }

  render() {
    const {
      presentation,
      widget,
    } = this.props;

    const siteName = get(widget, 'properties.iframely-embed-site', '');
    const siteNameLowerCase = siteName.toLowerCase();
    const headline = get(widget, 'properties.headline', '');
    const { subType } = this.context;

    /*
      Facebook embeds do not handle responsive behavior correctly.
      To avoid cutting off the embed or causing horizontal overflow,
      we use the existing Ramen Facebook embed with responsive sizing set.
     */
    if (siteNameLowerCase === 'facebook') {
      const reshapedEmbedObject = {
        ...widget,
        properties: {
          'facebookplus-post-url': widget.fallbackUrl,
        },
      };

      return (
        <FacebookEmbed
          presentation={presentation}
          widget={reshapedEmbedObject}
          facebookPostType="post"
        />
      );
    }

    // Iframely uses an iframe to wrap all social embeds.
    // The iframe is injected into a container for rendering.
    const { baseline: iframelyEmbedHTML = '', fallbackUrl } = widget;

    let iframelyEmbed = htmlSetIframeLoadingAttributes(iframelyEmbedHTML, 'lazy');

    iframelyEmbed = htmlSetIframeDataSrcAttributes(iframelyEmbed, fallbackUrl);
    iframelyEmbed = htmlSetIframeTitle(iframelyEmbed, siteName, headline);
    // Deep links (e.g., immediately scroll into view) require an element id
    const shouldEnableDatavizDeepLink = (fallbackUrl || '').match('https://dataviz.nbcnews.com');
    const id = shouldEnableDatavizDeepLink ? fallbackUrl.split('/').slice(-2)[0] : null;
    const isEmbedTimeline = (fallbackUrl || '').match('/embedded-pkg/timeline');

    const { lastReportedHeight, isMobileOrTabletBreakpoint } = this.state;

    const isLiveBlog = subType === 'liveBlog';

    const renderEmbedMarkup = (
      <div
        ref={this.iframeWrapper}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: iframelyEmbed }}
      />
    );

    return (
      <div
        id={id}
        data-test="iframely-wrapper"
        className={classNames(
          'embed-widget__use-presentation',
          `embed-widget__iframely-${siteNameLowerCase}`,
          { 'embed-widget__iframely-timeline': isEmbedTimeline },
        )}
        style={{ '--iframe-height': `${lastReportedHeight}px` }}
      >
        {isLiveBlog && !LAMBDA_TEST ? (
          <LazyLoad unmountIfInvisible offset={isMobileOrTabletBreakpoint ? 100 : 500}>
            <ReportUnmounting onUnmount={() => this.onLazyLoadUnmounting()}>
              {renderEmbedMarkup}
            </ReportUnmounting>
          </LazyLoad>
        ) : (
          renderEmbedMarkup
        )}
      </div>
    );
  }
}
