import React from 'react';
import PropTypes from 'prop-types';
import { decodeString } from 'lib/stringUtils';
import * as CustomPropTypes from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';

// dont think that this is working properly. need to compare
// with uat. for now it is rendering markup
export class TweetEmbed extends React.Component {
  static propTypes = {
    html: PropTypes.string,
    widget: CustomPropTypes.widget.isRequired,
  };

  static defaultProps = {
    html: null,
  }

  /**
   * This method loads the external script for X platform widgets and sets the necessary attributes.
   * After the script is loaded, it re-parses and renders any X embeds on the page.
   * This is particularly useful in a Single Page Application (SPA) where navigating to a new article
   * does not reload the entire page, so the script needs to re-parse the new content.
   */
  componentDidMount() {
    loadScript('//platform.twitter.com/widgets.js', {
      attributes: { defer: true, charset: 'utf-8' },
    })
      // re-running `.process` here since in SPA mode, the script needs to re-parse
      // embeds on the page when navigating to a new article
      .then(() => {
        window.twttr.widgets.load(this.embed);
      });
  }

  render() {
    const { html, widget } = this.props;

    // TODO move script-strip to class all other widgets can extend.
    // Strip script tags from content.
    const markup = (html || decodeString(widget.baseline))
      .replace(/<script[^<]+src="([^"]+)"[^<]*><\/script>/gi, '');

    /* eslint-disable react/no-danger */
    return (
      <div
        ref={(el) => { this.embed = el; }}
        className="embed-widget__use-presentation"
        dangerouslySetInnerHTML={{ __html: markup }}
      />
    );
    /* eslint-enable react/no-danger */
  }
}
